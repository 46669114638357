/*IMPORTS*/
import {environment} from "./environments/environment";
import {UserModel} from "./models/user.model";
import {SkuModel} from "./models/sku.model";
import {ShippingregionModel} from "./models/shippingregion.model";
import {ShippingmethodModel} from "./models/shippingmethod.model";
import "promise/polyfill";
import "whatwg-fetch";
declare var ActiveXObject: any;
declare var Stripe: any;
declare var paypal: any;

declare interface BasketItem {
    sku_id: string | null;
    quantity: number | null;
    priceinclvat: number | null;
}

declare interface Basket {
    basketitems: BasketItem[];
    shippingmethodid: string | null;
}

// @ts-ignore
!function () {
    // @ts-ignore
    var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
        analytics.invoked = !0;
        analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];
        // @ts-ignore
        analytics.factory = function (t) {
            return function () {
                var e = Array.prototype.slice.call(arguments);
                e.unshift(t);
                analytics.push(e);
                return analytics
            }
        };
        for (var t = 0; t < analytics.methods.length; t++) {
            var e = analytics.methods[t];
            analytics[e] = analytics.factory(e)
        }
        // @ts-ignore
        analytics.load = function (t, e) {
            var n = document.createElement("script");
            n.type = "text/javascript";
            n.async = !0;
            n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
            var a = document.getElementsByTagName("script")[0];
            // @ts-ignore
            a.parentNode.insertBefore(n, a);
            analytics._loadOptions = e
        };
        analytics.SNIPPET_VERSION = "4.1.0";
        analytics.load("im1VwPo7EeftzuNF5aK4GN0hCrI8Xl4e");
        analytics.page();
    }
}();

export class NxtCore {

    readonly PUBLIC_AUTH_TOKEN: string;
    readonly CURRENCY: string;
    public nxt_missing_first_name: HTMLElement | null = null;
    public nxt_missing_last_name: HTMLElement | null = null;
    public nxt_missing_street: HTMLElement | null = null;
    public nxt_missing_city: HTMLElement | null = null;
    public nxt_missing_postalcode: HTMLElement | null = null;
    public nxt_missing_state: HTMLElement | null = null;
    public nxt_missing_country: HTMLElement | null = null;
    public nxt_missing_email: HTMLElement | null = null;
    public nxt_missing_shipping_first_name: HTMLElement | null = null;
    public nxt_missing_shipping_last_name: HTMLElement | null = null;
    public nxt_missing_shipping_street: HTMLElement | null = null;
    public nxt_missing_shipping_city: HTMLElement | null = null;
    public nxt_missing_shipping_postalcode: HTMLElement | null = null;
    public nxt_missing_shipping_state: HTMLElement | null = null;
    public nxt_missing_shipping_country: HTMLElement | null = null;
    public next_payment: HTMLElement | null = null;
    public show_shipping_details: HTMLElement | null = null;
    public nxt_shipping_country: HTMLElement | null = null;
    public nxt_billing_country: HTMLElement | null = null;
    public nxt_checkout_subtotal: HTMLElement | null = null;
    public nxt_checkout_vat_total: HTMLElement | null = null;
    public nxt_shipping_details: HTMLElement | null = null;
    public nxt_checkout_total: HTMLElement | null = null;
    public nxt_loading: HTMLElement | null = null;
    public nxt_billing_firstname: HTMLElement | null = null;
    public nxt_shipping_firstname: HTMLElement | null = null;
    public nxt_billing_lastname: HTMLElement | null = null;
    public nxt_shipping_lastname: HTMLElement | null = null;
    public nxt_billing_street: HTMLElement | null = null;
    public nxt_shipping_street: HTMLElement | null = null;
    public nxt_billing_email: HTMLElement | null = null;
    public nxt_billing_city: HTMLElement | null = null;
    public nxt_shipping_city: HTMLElement | null = null;
    public nxt_billing_zip: HTMLElement | null = null;
    public nxt_shipping_zip: HTMLElement | null = null;
    public nxt_billing_state: HTMLElement | null = null;
    public nxt_shipping_state: HTMLElement | null = null;
    public nxt_shipping_comment: HTMLElement | null = null;
    public nxt_basket: HTMLElement | null = null;
    public basket_row_template: any = null;
    public shipping_option_template: any = null;
    public buy_now_buttons: HTMLCollection | null = null;
    public basket_items: HTMLCollection | null = null;
    public nxt_errors: HTMLCollection | null = null;
    public nxt_product_description: HTMLElement | null = null;
    public nxt_shipping_selector: HTMLElement | null = null;
    public nxt_error_message: HTMLElement | null = null;
    public nxt_invalid_number: HTMLElement | null = null;
    public nxt_invalid_cvc: HTMLElement | null = null;
    public nxt_incorrect_cvc: HTMLElement | null = null;
    public nxt_card_declined: HTMLElement | null = null;
    public nxt_expired_card: HTMLElement | null = null;
    public nxt_invalid_expiry_month: HTMLElement | null = null;
    public nxt_invalid_expiry_year: HTMLElement | null = null;
    public nxt_invalid_card_type: HTMLElement | null = null;
    public nxt_incorrect_zip: HTMLElement | null = null;
    public nxt_incorrect_number: HTMLElement | null = null;
    public nxt_incomplete_number: HTMLElement | null = null;
    public stripe: any;
    public elements: any;

    constructor(
        public_auth_token: string,
    ) {
        this.PUBLIC_AUTH_TOKEN = public_auth_token;
        this.CURRENCY = "EUR";
        console.log("this", this);
        /* REGISTER ALL NXT ELEMENTS*/
        this.generateDomBindings();
        /* CREATE EVENT LISTENERS*/
        this.createEventListeners();
    }

    checkIfIntranetOrTrusted() {
        window.status = 'checkIntranetOrTrustedMode';
        if (window.status === 'checkIntranetOrTrustedMode') {
            console.log("checkIntranetOrTrustedMode === true");
            return true;
        } else {
            console.log("checkIntranetOrTrustedMode === false");
            return false;
        }
    }

    createPayPalCard() {
        this.checkIfIntranetOrTrusted();
        paypal.Buttons({
            style: {
                layout: 'horizontal',
                color:  'black',
                shape:  'rect',
                label:  'pay',
                tagline: false,
                height: 40
            },
            createOrder: () => {
                this.resetPaymentErrors();
                const basket: Basket = this.basket_getcookie();
                const customer: UserModel = this.customer_getcookie();
                return fetch(environment.BASEURL + '/api/payments/createintent', {
                    method: 'post',
                    headers: {
                        'Authorization': 'Bearer ' + this.PUBLIC_AUTH_TOKEN,
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    body: "BasketItems=" + JSON.stringify(basket.basketitems) + "&paymentgateway=paypal" + "&customer=" + JSON.stringify(customer) + "&shippingcomment=" + customer.shippingcomment + "&shippingmethodid=" + basket.shippingmethodid
                }).then((res) => {
                    console.log(res);
                    return res.json();
                }).then((data) => {
                    console.log(data.detailresponse);
                    return data.detailresponse; // Use the same key name for order ID on the client and server
                });
            },
            onApprove: (data: any, actions: any) => {
                if (this.nxt_error_message) {
                    this.nxt_error_message.classList.add("hide");
                }
                return fetch(environment.BASEURL + "/api/payments/capturepaypalsuccess", {
                    method: 'post',
                    headers: {
                        'Authorization': 'Bearer ' + this.PUBLIC_AUTH_TOKEN,
                        'Content-Type':'application/x-www-form-urlencoded',
                    },
                    body: "paypalorderid=" + data.orderID
                }).then((res) => {
                    return res.json();
                }).then((details) => {
                    // The payment has succeeded. Display a success message.
                    this.basket_clear();
                    var cardbutton: any = document.getElementById('nxt-submit-payment');
                    var href = cardbutton.getAttribute("data-href");
                    window.location.href = href;
                }).catch((e) => {
                    if (this.nxt_error_message) {
                        this.nxt_error_message.classList.remove("hide");
                    }
                });
            }
        }).render('#paypal-button-container');
    }

    createStripeCard() {
        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        this.stripe = new Stripe(environment.stripe_api_key);
        this.elements = this.stripe.elements();
        const style = {
            base: {
                color: '#fff',
                cssSrc: 'https://fonts.googleapis.com/css?family=Rubik&display=swap',
                fontFamily: '"Rubik", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#BDB7A5'
                },
                iconColor: '#fff'
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        // Create an instance of the card Element.
        var cardelement = this.elements.create('card', {style: style});
        // Add an instance of the card Element into the `card-element` <div>.
        cardelement.mount('#card-element');

        var cardbutton: any = document.getElementById('nxt-submit-payment');
        cardbutton.addEventListener('click', (ev: any) => {
            this.stripePayment(cardelement, cardbutton);
        });
    }

    generateDomBindings() {
        this.nxt_billing_firstname = document.getElementById("nxt-billing-firstname");
        this.nxt_shipping_firstname = document.getElementById("nxt-shipping-firstname");
        this.nxt_billing_lastname = document.getElementById("nxt-billing-lastname");
        this.nxt_shipping_lastname = document.getElementById("nxt-shipping-lastname");
        this.nxt_billing_street = document.getElementById("nxt-billing-street");
        this.nxt_shipping_street = document.getElementById("nxt-shipping-street");
        this.nxt_billing_email = document.getElementById("nxt-billing-email");
        this.nxt_billing_city = document.getElementById("nxt-billing-city");
        this.nxt_shipping_city = document.getElementById("nxt-shipping-city");
        this.nxt_billing_zip = document.getElementById("nxt-billing-zip");
        this.nxt_shipping_zip = document.getElementById("nxt-shipping-zip");
        this.nxt_billing_state = document.getElementById("nxt-billing-state");
        this.nxt_shipping_state = document.getElementById("nxt-shipping-state");
        this.nxt_shipping_comment = document.getElementById("nxt-shipping-comment");
        this.next_payment = document.getElementById("next-payment");
        this.nxt_missing_first_name = document.getElementById("nxt-missing-first-name");
        this.nxt_missing_last_name = document.getElementById("nxt-missing-last-name");
        this.nxt_missing_street = document.getElementById("nxt-missing-street");
        this.nxt_missing_city = document.getElementById("nxt-missing-city");
        this.nxt_missing_postalcode = document.getElementById("nxt-missing-postalcode");
        this.nxt_missing_state = document.getElementById("nxt-missing-state");
        this.nxt_missing_country = document.getElementById("nxt-missing-country");
        this.nxt_missing_email = document.getElementById("nxt-missing-email");
        this.nxt_missing_shipping_first_name = document.getElementById("nxt-missing-shipping-first-name");
        this.nxt_missing_shipping_last_name = document.getElementById("nxt-missing-shipping-last-name");
        this.nxt_missing_shipping_street = document.getElementById("nxt-missing-shipping-street");
        this.nxt_missing_shipping_city = document.getElementById("nxt-missing-shipping-city");
        this.nxt_missing_shipping_postalcode = document.getElementById("nxt-missing-shipping-postalcode");
        this.nxt_missing_shipping_state = document.getElementById("nxt-missing-shipping-state");
        this.nxt_missing_shipping_country = document.getElementById("nxt-missing-shipping-country");
        this.show_shipping_details = document.getElementById("nxt-show-shipping-details");
        this.nxt_shipping_country = document.getElementById("nxt-shipping-country");
        this.nxt_billing_country = document.getElementById("nxt-billing-country");
        this.nxt_checkout_subtotal = document.getElementById("nxt-checkout-subtotal");
        this.nxt_checkout_vat_total = document.getElementById("nxt-checkout-vat-total");
        this.nxt_shipping_details = document.getElementById("nxt-shipping-details");
        this.nxt_checkout_total = document.getElementById("nxt-checkout-total");
        this.nxt_loading = document.getElementById("nxt-loading");
        this.buy_now_buttons = document.getElementsByClassName("buy-now");
        this.nxt_errors = document.getElementsByClassName("nxt-error");
        this.basket_items = document.getElementsByClassName("nxt-basket-row");
        this.basket_row_template = document.getElementById("nxt-basket-row");
        this.shipping_option_template = document.getElementById("nxt-shipping-option");
        this.nxt_basket = document.getElementById("nxt-basket");
        this.nxt_product_description = document.getElementById("nxt-product-description");
        this.nxt_shipping_selector = document.getElementById("nxt-shipping-selector");
        this.nxt_error_message = document.getElementById("nxt-error-message");

        this.nxt_invalid_number = document.getElementById("nxt-invalid-number");
        this.nxt_invalid_cvc = document.getElementById("nxt-invalid-cvc");
        this.nxt_incorrect_cvc = document.getElementById("nxt-incorrect-cvc");
        this.nxt_card_declined = document.getElementById("nxt-card-declined");
        this.nxt_expired_card = document.getElementById("nxt-expired-card");
        this.nxt_invalid_expiry_month = document.getElementById("nxt-invalid-expiry-month");
        this.nxt_invalid_expiry_year = document.getElementById("nxt-invalid-expiry-year");
        this.nxt_invalid_card_type = document.getElementById("nxt-invalid-card_type");
        this.nxt_incorrect_zip = document.getElementById("nxt-incorrect-zip");
        this.nxt_incorrect_number = document.getElementById("nxt-incorrect-number");
        this.nxt_incomplete_number = document.getElementById("nxt-incomplete-number");
    }

    createEventListeners() {
        if (this.buy_now_buttons) {
            for (var i = 0; i < this.buy_now_buttons.length; i++) {
                this.buy_now_buttons[i].addEventListener('click', this.buyNow.bind(this, this.buy_now_buttons[i]), false);
            }
            if (this.next_payment) {
                this.next_payment.addEventListener("click", this.create_customer.bind(this, this.next_payment), false);
            }
            if (this.show_shipping_details) {
                this.show_shipping_details.addEventListener("click", this.toggleShippingDetails.bind(this, this.show_shipping_details), false);
            }
            /*if (this.nxt_shipping_country || this.nxt_billing_country) {
                this.api_getshippingregions(this.render_countryoptions.bind(this))
            }*/
            if (this.nxt_shipping_selector) {
                const customer_cookie: UserModel = this.customer_getcookie();
                let isocode = null;
                if (customer_cookie.shippingcountryiso) {
                    isocode = customer_cookie.shippingcountryiso;
                } else if (customer_cookie.countryiso) {
                    isocode = customer_cookie.countryiso;
                }
                console.log("customer_cookie", customer_cookie);
                console.log("isocode", isocode);
                this.api_getshippingmethods(isocode, this.render_shipping_options.bind(this))
            }
        }
    }

    insertAdjacentText(
        fragment: DocumentFragment,
        _class: string,
        position: "afterbegin" | "beforeend",
        content: string) {
        const element = fragment.querySelector(_class);
        if (element) {
            element.insertAdjacentText(position, content);
        } else {
            return;
        }
    }

    /* USER ACTIONS */
    buyNow(el: any) {
        // todo check each buy now button if correct data attributes are set
        const href = el.getAttribute("data-href");
        const sku_id = el.getAttribute("data-sku_id");
        const price = el.getAttribute("data-price");
        if (!href) {
            this.missingAttributeHandler("data-href");
        }
        if (!sku_id) {
            this.missingAttributeHandler("data-sku_id");
        }
        if (!price) {
            this.missingAttributeHandler("data-price");
        }
        this.basket_update(sku_id, 1, price, null);
        window.location.href = href;
    }

    /* UTILITY METHODS */
    getElementById(id: string): any {
        if (document.getElementById(id)) {
            return document.getElementById(id);
        }
        else {
            if (environment.ENVIRONMENT === "dev") {
                this.missingElementHandler(id);
            }
            return false;
        }
    }

    getAttribute(el: HTMLElement, attributename: string): any {
        if (el.getAttribute(attributename)) {
            return el.getAttribute(attributename);
        }
        else {
            this.missingAttributeHandler(attributename);
            return;
        }
    }

    missingAttributeHandler(attributename: string) {
        if (environment.ENVIRONMENT === "dev") {
            var div = document.createElement("div");
            div.style.position = "absolute";
            var class_name = "missing-element";
            var arr = div.className.split(" ");
            if (arr.indexOf(class_name) == -1) {
                div.className += " " + class_name;
            }
            div.innerHTML = "Missing an attribute: " + attributename;
            var lastChild: any = document.body.lastChild;
            document.body.insertBefore(div, lastChild.nextSibling);
            console.error("Missing an attribute: " + attributename);
        } else {
            console.error("Missing an attribute: " + attributename);
        }
    }

    missingElementHandler(elementname: string) {
        if (environment.ENVIRONMENT === "dev") {
            var div = document.createElement("div");
            div.style.position = "absolute";
            var class_name = "missing-element";
            var arr = div.className.split(" ");
            if (arr.indexOf(class_name) == -1) {
                div.className += " " + class_name;
            }
            div.innerHTML = "Missing a an element with required id: " + elementname;
            var lastChild: any = document.body.lastChild;
            document.body.insertBefore(div, lastChild.nextSibling);
            console.error("Missing an element with required id: " + elementname);
        }
        else {
            console.error("Missing an element with required id: " + elementname);
        }
    }

    resetPaymentErrors() {
        if (this.nxt_error_message) {
            this.nxt_error_message.classList.add("hide");
        }
        if (this.nxt_invalid_number) {
            this.nxt_invalid_number.classList.add("hide");
        }
        if (this.nxt_invalid_cvc) {
            this.nxt_invalid_cvc.classList.add("hide");
        }
        if (this.nxt_incorrect_cvc) {
            this.nxt_incorrect_cvc.classList.add("hide");
        }
        if (this.nxt_card_declined) {
            this.nxt_card_declined.classList.add("hide");
        }
        if (this.nxt_expired_card) {
            this.nxt_expired_card.classList.add("hide");
        }
        if (this.nxt_invalid_expiry_month) {
            this.nxt_invalid_expiry_month.classList.add("hide");
        }
        if (this.nxt_invalid_expiry_year) {
            this.nxt_invalid_expiry_year.classList.add("hide");
        }
        if (this.nxt_invalid_card_type) {
            this.nxt_invalid_card_type.classList.add("hide");
        }
        if (this.nxt_incorrect_zip) {
            this.nxt_incorrect_zip.classList.add("hide");
        }
        if (this.nxt_incorrect_number) {
            this.nxt_incorrect_number.classList.add("hide");
        }
        if (this.nxt_incomplete_number) {
            this.nxt_incomplete_number.classList.add("hide");
        }
    }
    /* PAYMENT FUNCTIONS*/
    stripePayment(cardelement: any, cardbutton: any) {

        this.resetPaymentErrors();
        const basket: Basket = this.basket_getcookie();
        cardbutton.disabled = true;

        const customer: UserModel = this.customer_getcookie();
        console.log("basket", basket);
        console.log("customer", customer);
        this.api_request(
            "/api/payments/createintent",
            "POST",
            "BasketItems=" + JSON.stringify(basket.basketitems) + "&paymentgateway=stripe" + "&customer=" + JSON.stringify(customer) + "&shippingcomment=" + customer.shippingcomment + "&shippingmethodid=" + basket.shippingmethodid,
            (val: any) => {
                // todo take care of error for creating intent
                // returns payment intent secret for the checkout
                console.log("client secret", val);
                const card_button = this.getElementById("nxt-submit-payment");
                card_button.setAttribute('data-secret', val);

                const customer = this.customer_load();
                if (customer) {
                    var clientSecret = card_button.dataset.secret;
                    console.log("clientSecret", clientSecret);
                    this.stripe.handleCardPayment(
                        clientSecret, cardelement, {
                            payment_method_data: {
                                billing_details: {name: customer.firstname + " " + customer.lastname}
                            }
                        }
                    ).then((result: any) => {
                        cardbutton.disabled = false;
                        if (result.error) {
                            // Display error.message in your UI.
                            if (this.nxt_error_message) {
                                switch (result.error.code) {
                                    case "invalid_number":
                                        if (this.nxt_invalid_number) {
                                            this.nxt_invalid_number.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "invalid_cvc":
                                        if (this.nxt_invalid_cvc) {
                                            this.nxt_invalid_cvc.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "incorrect_cvc":
                                        if (this.nxt_incorrect_cvc) {
                                            this.nxt_incorrect_cvc.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "card_declined":
                                        if (this.nxt_card_declined) {
                                            this.nxt_card_declined.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "expired_card":
                                        if (this.nxt_expired_card) {
                                            this.nxt_expired_card.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "invalid_expiry_month":
                                        if (this.nxt_invalid_expiry_month) {
                                            this.nxt_invalid_expiry_month.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "invalid_expiry_year":
                                        if (this.nxt_invalid_expiry_year) {
                                            this.nxt_invalid_expiry_year.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "invalid_card_type":
                                        if (this.nxt_invalid_card_type) {
                                            this.nxt_invalid_card_type.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "incorrect_zip":
                                        if (this.nxt_incorrect_zip) {
                                            this.nxt_incorrect_zip.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "incorrect_number":
                                        if (this.nxt_incorrect_number) {
                                            this.nxt_incorrect_number.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    case "incomplete_number":
                                        if (this.nxt_incomplete_number) {
                                            this.nxt_incomplete_number.classList.remove("hide");
                                        } else {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                        break;
                                    default:
                                        if (this.nxt_error_message) {
                                            this.nxt_error_message.classList.remove("hide");
                                        }
                                }
                            }
                            console.error(result);
                        } else {
                            var href = cardbutton.getAttribute("data-href");
                            // The payment has succeeded. Display a success message.
                            this.basket_clear();
                            window.location.href = href;
                            console.log(result);
                        }
                    });
                }
            });
    }

    /* API FUNCTIONS */
    api_getproduct(productid: string, callback: any) {
        this.api_request("/api/products/getproductbyid?productid=" + productid, "GET", null, callback);
    }

    api_getshippingregions(callback: any) {
        this.api_request("/api/shipping/getshippingregions", "GET", null, callback);
    }

    api_getshippingmethods(countryiscode: string | null, callback: any) {
        let path = "/api/shipping/getshippingmethods";
        if (countryiscode) {
            path += "?countryisocode=" + countryiscode;
        }
        this.api_request(path, "GET", null, callback);
    }

    api_getsku(skuid: string | null, callback: any) {
        this.api_request("/api/skus/getskubyid?skuid=" + skuid, "GET", null, callback);
    }

    api_request(apipath: string, method: string, body: any, callback: any) {
        if (body === void 0) {
            body = null;
        }
        if (this.nxt_loading) {
            this.nxt_loading.classList.remove("hide");
        }
        // Old compatibility code, no longer needed.
        var httpRequest = null;
        if ((<any>window).XMLHttpRequest) { // Mozilla, Safari, IE7+ ...
            httpRequest = new XMLHttpRequest();
        }
        else if ((<any>window).ActiveXObject) { // IE 6 and older
            httpRequest = new ActiveXObject("Microsoft.XMLHTTP");
        }
        /*tso*/
        httpRequest.onreadystatechange = this.api_processResponse.bind(this, httpRequest, callback);
        httpRequest.open(method, environment.BASEURL + apipath, true);
        if (method === "POST" || method === "post") {
            httpRequest.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        }
        console.log("this.PUBLIC_AUTH_TOKEN", this.PUBLIC_AUTH_TOKEN);
        httpRequest.setRequestHeader('Authorization', 'Bearer ' + this.PUBLIC_AUTH_TOKEN);
        httpRequest.send(body);
    }

    selected_shipping_option(el: HTMLInputElement, id: string, price: number) {
        if (this.nxt_shipping_selector) {
            const inputs = this.nxt_shipping_selector.getElementsByTagName('input');
            for (var i = 0; i < inputs.length; i++) {
                inputs[i].checked = false;
            }
            el.checked = true;
            console.log("selected_shipping_option id:", id);
            console.log("selected_shipping_option price:", price);
            this.basket_update(null, null, null, id);
            this.update_price(price);
        }
    }

    render_shipping_options(shippingmethods: ShippingmethodModel[]) {
        console.log("render_shipping_options");
        if (this.shipping_option_template) {
            for (var i = 0; i < shippingmethods.length; i++) {
                let shippingmethod: ShippingmethodModel = shippingmethods[i];

                var clone: HTMLElement = this.shipping_option_template.cloneNode(true);
                // todo store class references in bindings constructor
                if (clone.querySelector(".nxt-shipping-option-name")) {
                    (<any>clone.querySelector(".nxt-shipping-option-name")).innerHTML = shippingmethod.name;
                }
                if (clone.querySelector(".nxt-shipping-option-description")) {
                    (<any>clone.querySelector(".nxt-shipping-option-description")).innerHTML = shippingmethod.description;
                }
                if (clone.querySelector(".nxt-shipping-option-price")) {
                    (<any>clone.querySelector(".nxt-shipping-option-price")).innerHTML = shippingmethod.priceexclvat + " " + this.CURRENCY;
                }
                clone.id = shippingmethod.id;
                clone.classList.remove("hide");
                (<any>clone.querySelector(".nxt-shipping-option-checkbox")).value = shippingmethod.id;
                if (i === 0) {
                    (<any>clone.querySelector(".nxt-shipping-option-checkbox")).checked = true;
                    console.log("shippingmethod.priceexclvat", parseFloat((<any>shippingmethod.priceexclvat)));
                    this.basket_update(null, null, null, shippingmethod.id);
                    this.update_price(shippingmethod.priceexclvat);
                } else {
                    (<any>clone.querySelector(".nxt-shipping-option-checkbox")).checked = false;
                }

                const nxt_shipping_option_checkbox: any = clone.querySelector(".nxt-shipping-option-checkbox");
                if (nxt_shipping_option_checkbox) {
                    nxt_shipping_option_checkbox.addEventListener("click", () => {
                        this.selected_shipping_option(nxt_shipping_option_checkbox, shippingmethod.id, parseFloat((<any>shippingmethod.priceexclvat)))
                    });
                } else {
                    this.missingElementHandler("qty_minus");
                    return;
                }
                // -> insert template
                if (this.nxt_shipping_selector) {
                    this.nxt_shipping_selector.appendChild(clone);
                }
                this.generateDomBindings();
            }
        }
    }

    render_countryoptions(shippingregions: ShippingregionModel[]) {
        if (this.nxt_shipping_country) {
            this.render_option(this.nxt_shipping_country, "", "");
            shippingregions.forEach((val: ShippingregionModel) => {
                this.render_option(this.nxt_shipping_country, val.countryname, val.countryisocode)
            });
        }
        if (this.nxt_billing_country) {
            this.render_option(this.nxt_billing_country, "", "");
            shippingregions.forEach((val: ShippingregionModel) => {
                this.render_option(this.nxt_billing_country, val.countryname, val.countryisocode)
            });
        }
    }

    render_option(element: HTMLElement | null, option_text: string, option_value: string) {
        if (element) {
            var opt = document.createElement('option');
            opt.appendChild(document.createTextNode(option_text));
            opt.value = option_value;
            element.appendChild(opt);
        }
    }

    /**
     0 (uninitialized) or (request not initialized)
     1 (loading) or (server connection established)
     2 (loaded) or (request received)
     3 (interactive) or (processing request)
     4 (complete) or (request finished and response is ready)
     **/
    /** INTERNAL FUNCTIONS **/
    api_processResponse(httpRequest: any, callback: any) {
        const nxt_error_message = document.getElementById("nxt-error-message");
        if (nxt_error_message) {
            nxt_error_message.classList.add("hide");
        }
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
            // Everything is good, the response was received.
            if (httpRequest.status === 200) {
                if (this.nxt_loading) {
                    this.nxt_loading.classList.add("hide");
                }
                // Perfectt
                var res = httpRequest.responseText;
                res = JSON.parse(res);
                callback(res.detailresponse);
            } else {
                if (this.nxt_loading) {
                    this.nxt_loading.classList.add("hide");
                }
                if (nxt_error_message) {
                    nxt_error_message.classList.remove("hide");
                }
                console.error(httpRequest);
                // There was a problem with the request.
                // For example, the response may have a 404 (Not Found)
                // or 500 (Internal Server Error) response code.
            }
        }
        else {
            // Not ready yet.
        }
    }

    /* ERROR HANDLERS */
    errorHandler(e: any) {
        console.error(e);
        // todo show some kind of error message to the user
    }

    update_price(shippingcosts: number = 0) {
        console.log("update_price");
        let price_incl_vat: any = 0;
        console.log("price_incl_vat", price_incl_vat);
        const basket: Basket = this.basket_getcookie();
        const basket_items: BasketItem[] = basket.basketitems;
        // todo move to bindings constructor
        if (basket_items) {
            for (var i = 0; i < basket_items.length; i++) {
                console.log("basket_items[i].priceinclvat", basket_items[i].priceinclvat);
                const product_price_incl_vat = parseFloat(<any>basket_items[i].priceinclvat);
                console.log(basket_items[i].priceinclvat);
                const quantity = parseInt(<any>basket_items[i].quantity);
                price_incl_vat += product_price_incl_vat * quantity;
            }
            const vat = 25; // todo make vat dynamic
            if (this.nxt_checkout_subtotal) {
                /*const subtotal_excl_vat = (price_incl_vat / (100 + vat)) * 100;
                this.nxt_checkout_subtotal.innerText = subtotal_excl_vat.toFixed(2) + " " + this.CURRENCY;*/
                this.nxt_checkout_subtotal.innerText = price_incl_vat.toFixed(2) + " " + this.CURRENCY;
            }

            if (shippingcosts) {
                console.log("shippingcosts", shippingcosts);
                price_incl_vat = price_incl_vat + parseFloat((<any>shippingcosts));
            }

            if (this.nxt_checkout_total) {
                console.log("price_incl_vat", price_incl_vat);
                console.log("price_incl_vat", price_incl_vat);
                this.nxt_checkout_total.innerText = price_incl_vat.toFixed(2) + " " + this.CURRENCY;
            }

            if (
                this.nxt_checkout_vat_total
            ) {
                const price_excl_vat = (price_incl_vat / (100 + vat)) * 100;
                const diff = price_incl_vat - price_excl_vat;
                this.nxt_checkout_vat_total.innerText = diff.toFixed(2) + " " + this.CURRENCY;
            }
        }
    }

    /* BASKET FUNCTIONS */
    basket_increaseQuantity(skuid: string, skuprice: number) {
        const basket_row: HTMLElement = this.getElementById(skuid);
        if (!basket_row) {
            this.missingElementHandler("Basked row with id:" + skuid);
        }
        // todo move basket_row query selectors to bindings constructor
        var current_value: number = parseInt((<any>basket_row.querySelector(".nxt-basket-quantity")).value);
        if (basket_row.querySelector(".nxt-basket-quantity-available")) {
            var max_available: number = parseInt((<any>basket_row.querySelector(".nxt-basket-quantity-available")).innerText);
        } else {
            var max_available: number = 5000000000;
        }

        if (current_value >= max_available) {
            return;
        }
        (<any>basket_row.querySelector(".nxt-basket-quantity")).value = current_value + 1;
        this.basket_update(skuid, (<any>basket_row.querySelector(".nxt-basket-quantity")).value, skuprice, null);
        this.update_price();
    }

    customer_load() {
        var customer_cookie: UserModel = this.customer_getcookie();
        if (!customer_cookie) {
            return;
        }
        this.render_customer(customer_cookie);
        return customer_cookie;
    }

    toggleShippingDetails(el: HTMLElement) {
        if (this.nxt_shipping_details) {
            if (this.nxt_shipping_details.classList.contains("hide")) {
                this.nxt_shipping_details.classList.remove("hide");

            } else {
                this.nxt_shipping_details.classList.add("hide");
            }
        }
    }

    render_customer(customer: UserModel) {
        console.log("Render customer", customer);
        if (this.nxt_billing_firstname) {
            (<any>this.nxt_billing_firstname).value = customer.firstname;
        }
        if (this.nxt_shipping_firstname) {
            (<any>this.nxt_shipping_firstname).value = customer.shippingfirstname;
        }
        if (this.nxt_billing_lastname) {
            (<any>this.nxt_billing_lastname).value = customer.lastname;
        }
        if (this.nxt_shipping_lastname) {
            (<any>this.nxt_shipping_lastname).value = customer.shippinglastname;
        }
        if (this.nxt_billing_street) {
            (<any>this.nxt_billing_street).value = customer.street;
        }
        if (this.nxt_shipping_street) {
            (<any>this.nxt_shipping_street).value = customer.shippingstreet;
        }
        if (this.nxt_billing_email) {
            (<any>this.nxt_billing_email).value = customer.email;
        }
        if (this.nxt_billing_country) {
            (<any>this.nxt_billing_country).value = customer.countryname;
        }
        if (this.nxt_shipping_country) {
            (<any>this.nxt_shipping_country).value = customer.shippingcountryname;
        }
        if (this.nxt_billing_city) {
            (<any>this.nxt_billing_city).value = customer.city;
        }
        if (this.nxt_shipping_city) {
            (<any>this.nxt_shipping_city).value = customer.shippingcity;
        }
        if (this.nxt_billing_zip) {
            (<any>this.nxt_billing_zip).value = customer.postalcode;
        }
        if (this.nxt_shipping_zip) {
            (<any>this.nxt_shipping_zip).value = customer.shippingpostalcode;
        }
        if (this.nxt_billing_state) {
            (<any>this.nxt_billing_state).value = customer.state;
        }
        if (this.nxt_shipping_state) {
            (<any>this.nxt_shipping_state).value = customer.shippingstate;
        }
        if (this.nxt_shipping_comment) {
            (<any>this.nxt_shipping_comment).value = customer.shippingcomment;
        }
        return;
    }

    check_customer_fields() {
        if (this.nxt_errors) {
            for (var i = 0; i < this.nxt_errors.length; i++) {
                this.nxt_errors[i].classList.add("hide");
            }
            if (!(<any>this.nxt_billing_firstname).value) {
                (<any>this.nxt_missing_first_name).classList.remove("hide");
                return false;
            }
            if (!(<any>this.nxt_billing_lastname).value) {
                (<any>this.nxt_missing_last_name).classList.remove("hide");
                return false;
            }
            if (!(<any>this.nxt_billing_street).value) {
                (<any>this.nxt_missing_street).classList.remove("hide");
                return false;
            }
            if (!(<any>this.nxt_billing_email).value) {
                (<any>this.nxt_missing_email).classList.remove("hide");
                return false;
            }
            if (!(<any>this.nxt_billing_country).value) {
                (<any>this.nxt_missing_country).classList.remove("hide");
                return false;
            }
            if (!(<any>this.nxt_billing_city).value) {
                (<any>this.nxt_missing_city).classList.remove("hide");
                return false;
            }
            if (!(<any>this.nxt_billing_zip).value) {
                (<any>this.nxt_missing_postalcode).classList.remove("hide");
                return false;
            }
            /*if (!(<any>this.nxt_billing_state).value) {
                (<any>this.nxt_missing_state).classList.remove("hide");
                return false;
            }*/

            if (!this.nxt_shipping_details || !this.nxt_shipping_details.classList.contains("hide")) {
                if (!(<any>this.nxt_shipping_firstname).value) {
                    (<any>this.nxt_missing_shipping_first_name).classList.remove("hide");
                    return false;
                }
                if (!(<any>this.nxt_shipping_lastname).value) {
                    (<any>this.nxt_missing_shipping_last_name).classList.remove("hide");
                    return false;
                }
                if (!(<any>this.nxt_shipping_street).value) {
                    (<any>this.nxt_missing_shipping_street).classList.remove("hide");
                    return false;
                }
                if (!(<any>this.nxt_shipping_country).value) {
                    (<any>this.nxt_missing_shipping_country).classList.remove("hide");
                    return false;
                }
                if (!(<any>this.nxt_shipping_city).value) {
                    (<any>this.nxt_missing_shipping_city).classList.remove("hide");
                    return false;
                }
                if (!(<any>this.nxt_shipping_zip).value) {
                    (<any>this.nxt_missing_shipping_postalcode).classList.remove("hide");
                    return false;
                }
                /*if (!(<any>this.nxt_shipping_state).value) {
                    (<any>this.nxt_missing_shipping_state).classList.remove("hide");
                    return false;
                }*/
            }
            return true;
        }
    }

    create_customer(el: HTMLElement) {
        if (!this.check_customer_fields()) {
            return;
        }
        let shippingcountryname = null;
        if (this.nxt_shipping_country) {
            if ((<any>this.nxt_shipping_country).options[(<any>this.nxt_shipping_country).selectedIndex]) {
                shippingcountryname = (<any>this.nxt_shipping_country).options[(<any>this.nxt_shipping_country).selectedIndex].text;
            }
        }
        let countryname = null;
        if (this.nxt_billing_country) {
            countryname = (<any>this.nxt_billing_country).options[(<any>this.nxt_billing_country).selectedIndex].text;
        }

        const customer: UserModel = {
            accounttype: "1",
            id: null,
            firstname: this.nxt_billing_firstname ? (<any>this.nxt_billing_firstname).value : null,
            shippingfirstname: this.nxt_shipping_firstname ? (<any>this.nxt_shipping_firstname).value : null,
            lastname: this.nxt_billing_lastname ? (<any>this.nxt_billing_lastname).value : null,
            shippinglastname: this.nxt_shipping_lastname ? (<any>this.nxt_shipping_lastname).value : null,
            street: this.nxt_billing_street ? (<any>this.nxt_billing_street).value : null,
            shippingstreet: this.nxt_shipping_street ? (<any>this.nxt_shipping_street).value : null,
            email: this.nxt_billing_email ? (<any>this.nxt_billing_email).value : null,
            referalcode: null,
            gender: null,
            countryname: countryname,
            countryiso: this.nxt_billing_country ? (<any>this.nxt_billing_country).value : null,
            shippingcountryname: shippingcountryname,
            shippingcountryiso: this.nxt_shipping_country ? (<any>this.nxt_shipping_country).value : null,
            currency: null,
            city: this.nxt_billing_city ? (<any>this.nxt_billing_city).value : null,
            shippingcity: this.nxt_shipping_city ? (<any>this.nxt_shipping_city).value : null,
            postalcode: this.nxt_billing_zip ? (<any>this.nxt_billing_zip).value : null,
            shippingpostalcode: this.nxt_shipping_zip ? (<any>this.nxt_shipping_zip).value : null,
            state: this.nxt_billing_state ? (<any>this.nxt_billing_state).value : null,
            shippingstate: this.nxt_shipping_state ? (<any>this.nxt_shipping_state).value : null,
            shippingsameasbilling: null,
            shippingcomment: this.nxt_shipping_comment ? (<any>this.nxt_shipping_comment).value : null
        };
        this.basket_update_customer(customer);
        window.location.href = this.getAttribute(el, "data-href");
    }

    basket_update_customer(user: UserModel) {
        document.cookie = "customer=" + JSON.stringify(user);
    }

    convertCountriesToIso(country: any) {
        if (country === "Austria" || country === "austria") {
            return "AT";
        }
        if (country === "Switzerland" || country === "switzerland") {
            return "CH";
        }
        if (country === "Germany" || country === "germany") {
            return "DE";
        }
        if (country === "United Kingdom" || country === "united kingdom") {
            return "UK";
        }
        if (country === "United States" || country === "united states") {
            return "US";
        }
        if (country === "Italy" || country === "italy") {
            return "IT";
        }
        if (country === "France" || country === "france") {
            return "FR";
        }
        return null;
        // todo expand to include all countries
    }

    basket_decreaseQuantity(skuid: string, skuprice: number) {
        console.log("basket_decreaseQuantity");
        const basket_row: HTMLElement = this.getElementById(skuid);
        if (!basket_row) {
            this.missingElementHandler("Basked row with id:" + skuid);
        }
        // todo set basket items object in bindings constructor
        var current_value: number = parseInt((<any>basket_row.querySelector(".nxt-basket-quantity")).value);
        if (current_value < 2) {
            return;
        }

        (<any>basket_row.querySelector(".nxt-basket-quantity")).value = current_value - 1;
        /*(<any>this.getElementById("nxt-basket-quantity").innerText) = current_value - 1;*/
        this.basket_update(skuid, (<any>basket_row.querySelector(".nxt-basket-quantity")).value, skuprice, null);
        this.update_price();
    }

    basket_clear() {
        document.cookie = "basket=" + JSON.stringify([]);
    }

    basket_getcookie() {
        var name = "basket" + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var basket_cookie = JSON.parse(c.substring(name.length, c.length));
                if (!basket_cookie) {
                    this.errorHandler(null);
                }
                console.log(basket_cookie);
                return basket_cookie;
            }
        }
    }

    customer_getcookie() {
        var name = "customer" + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var basket_cookie = JSON.parse(c.substring(name.length, c.length));
                if (!basket_cookie) {
                    this.errorHandler(null);
                }
                console.log(basket_cookie);
                return basket_cookie;
            }
        }
    }

    basket_load() {
        var basket_cookie: Basket = this.basket_getcookie();
        if (!basket_cookie) {
            return;
        }
        if (Array.isArray(basket_cookie.basketitems)) {
            basket_cookie.basketitems.forEach((val: BasketItem) => {
                console.log("test");
                this.api_getsku(val.sku_id, (data: SkuModel) => {
                    this.render_basket_item(data, val.quantity);
                    this.update_price();
                });
            });
        }
    }

    /*
    1) Check if any basket exists, if not create it
    2) If basket exsits check if item already added, if so increment quantity
    3) If item not already added push into basket array
    * */
    basket_update(sku_id: string | null, quantity: any | null, skupriceinclvat: number | null, shippingmethodid: string | null = null) {
        let basket_cookie: Basket = this.basket_getcookie();
        if (!sku_id || !quantity && shippingmethodid) {
            basket_cookie.shippingmethodid = shippingmethodid;
        }
        if (sku_id && quantity) {
            if (!basket_cookie || !Array.isArray(basket_cookie.basketitems)) {
                console.log("Creating new basket");
                basket_cookie = {
                    shippingmethodid: null,
                    basketitems: [
                        {
                            quantity: quantity,
                            sku_id: sku_id,
                            priceinclvat: skupriceinclvat
                        }
                    ]
                };
            } else {
                let exists = false;
                console.log("Checking for existing item");
                basket_cookie.basketitems.forEach((item: BasketItem) => {
                    if (item.sku_id == sku_id) {
                        exists = true;
                        item.quantity = quantity;
                    }
                });
                if (!exists) {
                    basket_cookie.basketitems.push(
                        {
                            quantity: quantity,
                            sku_id: sku_id,
                            priceinclvat: skupriceinclvat
                        }
                    )
                }
            }
        }
        document.cookie = "basket=" + JSON.stringify(basket_cookie);
    }

    /* RENDER FUNCTIONS */
    render_basket_item(skudata: SkuModel, quantity: number | null = 1) {
        if (this.basket_row_template) {
            var clone: HTMLElement = this.basket_row_template.cloneNode(true);
            if (clone) {
                clone.id = skudata.id;
            } else {
                this.missingElementHandler("nxt-basket-row");
            }
            // todo store class references in bindings constructor

            if (clone.querySelector(".nxt-basket-product-title")) {
                (<any>clone.querySelector(".nxt-basket-product-title")).innerHTML = skudata.productname;
            }
            if (clone.querySelector(".nxt-basket-product-description")) {
                (<any>clone.querySelector(".nxt-basket-product-description")).innerHTML = skudata.productdescription;
            }
            if (clone.querySelector(".nxt-basket-product-sku")) {
                (<any>clone.querySelector(".nxt-basket-product-sku")).innerHTML = skudata.skuproperty1value;
            }
            if (clone.querySelector(".nxt-basket-product-price")) {
                (<any>clone.querySelector(".nxt-basket-product-price")).innerHTML = <any>skudata.priceexclvat + " " + skudata.currency;
            }
            if (clone.querySelector(".nxt-basket-quantity-available")) {
                (<any>clone.querySelector(".nxt-basket-quantity-available")).innerHTML = <any>skudata.quantity;
            }

            /*this.insertAdjacentText(clone, ".nxt-basket-product-title", "afterbegin", skudata.productname);
            this.insertAdjacentText(clone, ".nxt-basket-product-description", "afterbegin", skudata.productdescription);
            this.insertAdjacentText(clone, ".nxt-basket-product-sku", "afterbegin", skudata.skuproperty1value);
            this.insertAdjacentText(clone, ".nxt-basket-product-price", "afterbegin", <any>skudata.priceexclvat + " " + skudata.currency);
            this.insertAdjacentText(clone, ".nxt-basket-quantity-available", "afterbegin", <any>skudata.quantity);*/

            (<any>clone.querySelector(".nxt-basket-quantity")).value = quantity;
            clone.classList.remove("hide");
            const qty_plus = clone.querySelector(".qty_plus");
            const qty_minus = clone.querySelector(".qty_minus");
            if (qty_plus) {
                qty_plus.addEventListener("click", () => {
                    this.basket_increaseQuantity(skudata.id, skudata.priceexclvat)
                });
            } else {
                this.missingElementHandler("qty_plus");
                return;
            }
            if (qty_minus) {
                qty_minus.addEventListener("click", () => {
                    this.basket_decreaseQuantity(skudata.id, skudata.priceexclvat)
                });
            } else {
                this.missingElementHandler("qty_minus");
                return;
            }
            // -> insert template
            if (this.nxt_basket) {
                this.nxt_basket.insertBefore(clone, this.nxt_basket.childNodes[0]);
            }
            this.generateDomBindings();
        }
    }

    render_hero_product(data: any) {
        if (this.nxt_product_description) {
            this.nxt_product_description.innerText = data.descriptionen;
        }
        if (this.buy_now_buttons) {
            for (var i = 0; i < this.buy_now_buttons.length; i++) {
                console.log("Buy now", data.skus[0].id);
                this.buy_now_buttons[i].setAttribute('data-sku_id', data.skus[0].id);
                this.buy_now_buttons[i].setAttribute('data-price', data.skus[0].priceexclvat);
            }
        }
    }
}

(<any>window).NxtCore = NxtCore;
